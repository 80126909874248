import Box from "../../Atomic/Box"

import React from "react"

export default function Input({ state, setState, placeholder, onEnter }) {
  return (
    <Box
      as={`input`}
      id={`username`}
      sx={{
        paddingX: "xs",
        border: "none",
        borderRadius: "sm",
        // backgroundColor: "greyScale.6",
        backgroundColor: "lightBackground",
        color: "white",
        width: "100%",
        "&:focus": {
          outline: "none",
        },
      }}
      minLength={1}
      maxLength={12}
      value={state}
      onChange={e => {
        setState(e.target.value)
      }}
      onKeyPress={e => {
        if (e.key === "Enter") {
          onEnter()
        }
      }}
      placeholder={placeholder}
    />
  )
}

import React, { useState } from "react"

import Box from "../components/Atomic/Box"
import Text from "../components/Atomic/Text"
import Grid from "../components/Atomic/Grid"
import Input from "../components/Runescape/Input"

import PageStructure from "../components/PageStructure"
import UserTable from "../containers/Runescape/UserTable"

export default function Runescape({ location }) {
  const [data, setData] = useState(undefined)
  const [username, setUsername] = useState("")

  async function getData() {
    if (username.length > 0) {
      fetch(`https://api.andreeastroem.se/hiscore?username=${username}`)
        .then(res => {
          return res.json()
        })
        .then(res => {
          setData(res)
        })
    }
  }

  return (
    <PageStructure
      pageTitle={`OSRS hiscore`}
      metaContent={`Hiscore comparer for oldschol runescape`}
      location={location}
    >
      <Box sx={{ width: "100%", backgroundColor: "background" }}>
        <Text as={`h1`}>Hiscore</Text>
        <Grid p={`sm`} sx={{ alignItems: "center" }}>
          <Text>Username</Text>
          <Box mx={[null, null, "sm"]}>
            <Input
              state={username}
              setState={setUsername}
              placeholder={"Username to lookup"}
              onEnter={() => {
                getData()
              }}
            />
          </Box>
        </Grid>
        {/* <Flex>{text && <Text>{text}</Text>}</Flex> */}
        <UserTable data={data} />
      </Box>
    </PageStructure>
  )
}

import Box from "../../components/Atomic/Box"
import Grid from "../../components/Atomic/Grid"

import React, { Fragment } from "react"

export default function UserTable({ data }) {
  return data !== undefined ? (
    <Grid columns={[4]} gap={0}>
      <Box
        sx={{
          fontSize: "lg",
          color: "pink",
          textShadow: "neon",
          fontWeight: "light",
          // backgroundColor: "greyScale.6",
          backgroundColor: "background",
        }}
      >
        Name
      </Box>
      <Box
        sx={{
          fontSize: "lg",
          color: "pink",
          textShadow: "neon",
          fontWeight: "light",
          // backgroundColor: "greyScale.6",
          backgroundColor: "background",
        }}
      >
        Level
      </Box>
      <Box
        sx={{
          fontSize: "lg",
          color: "pink",
          textShadow: "neon",
          fontWeight: "light",
          // backgroundColor: "greyScale.6",
          backgroundColor: "background",
        }}
      >
        Rank
      </Box>
      <Box
        sx={{
          fontSize: "lg",
          color: "pink",
          textShadow: "neon",
          fontWeight: "light",
          // backgroundColor: "greyScale.6",
          backgroundColor: "background",
        }}
      >
        Experience
      </Box>
      {data?.success ? (
        data?.body?.data?.skills.map(({ name, level, rank, xp }) => {
          return (
            <Fragment key={name}>
              <Box
                sx={{
                  color: "white",
                  backgroundColor: "background",
                  fontWeight: "thin",
                  color: "pink",
                  textShadow: "neon",
                }}
              >
                {name}
              </Box>
              <Box
                sx={{
                  color: "white",
                  backgroundColor: "background",
                  fontWeight: "thin",
                  color: "pink",
                  textShadow: "neon",
                }}
              >
                {level}
              </Box>
              <Box
                sx={{
                  color: "white",
                  backgroundColor: "background",
                  fontWeight: "thin",
                  color: "pink",
                  textShadow: "neon",
                }}
              >
                {rank}
              </Box>
              <Box
                sx={{
                  color: "white",
                  backgroundColor: "background",
                  fontWeight: "thin",
                  color: "pink",
                  textShadow: "neon",
                }}
              >
                {xp}
              </Box>
            </Fragment>
          )
        })
      ) : (
        <Box>Error fetching hiscore for {data?.username}</Box>
      )}
    </Grid>
  ) : (
    <Fragment></Fragment>
  )
}
